@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;
  > .#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }
  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {
    &-active,
    &:hover {
      color: $primaryColor;
    }
    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix}-top {
  .#{$prefix}-nav {
    .#{$prefix}-nav-wrap {
      .#{$prefix}-nav-list {
        .#{$prefix}-tab-active {
          .#{$prefix}-tab-btn {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.custom-tabs-volume-form {
  width: 160%;
  .#{$prefix}-nav::before {
    border-bottom: transparent;
  }
  .#{$prefix}-nav {
    margin: 0;
    .#{$prefix}-nav-wrap {
      .#{$prefix}-nav-list {
        .#{$prefix}-tab-active {
          border-bottom-color: $primaryColor;
          box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.05);
        }
        .#{$prefix}-tab {
          .#{$prefix}-tab-btn {
            .ant-row {
              padding: 8px 0;
              .ant-col:nth-child(1) {
                font-weight: 600;
                font-size: 16px;
                color: $textColor;
                padding-top: 4px;
              }
              .ant-col:nth-child(2) {
                .ant-btn-link {
                  margin-top: 3px;
                  .anticon {
                    margin-right: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .#{$prefix}-extra-content {
      padding: 9px 0;
    }
  }
  .#{$prefix}-content-holder {
    .#{$prefix}-content-top {
      .#{$prefix}-tabpane-active {
        border: 1px solid rgba(199, 199, 206, 1);
        border-radius: 4px;
        padding: 24px;
        margin-bottom: 16px;
        .ant-row {
          .ant-row {
            width: auto;
          }
          .shaping-form-item {
            margin: 24px 0 16px 0;
            .ant-row {
              .ant-card-bordered {
                margin: 8px 34px 24px 8px;
                .ant-card-head {
                  .ant-card-head-title {
                    font-weight: 300;
                    font-size: 15px;
                  }
                }
                .ant-card-body {
                  padding-right: 45px;
                }
              }
            }
            .ant-form-item {
              .ant-form-item-row {
                .ant-form-item-control {
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
