@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label > label {
    color: $textColor;
  }
  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }
  &-item {
    margin-bottom: 10px;
  }

  .#{$prefix}-item {
    .#{$prefix}-item-row {
      flex-direction: row;

      .#{$prefix}-item-label {
        text-align: right;
        padding: 6px 10px 0 10px;
        > label {
          color: $textColorSecondary;
          font-size: 12px;
        }
      }
    }
  }
}

.custom-form-item-quotations-general-infos {
  .#{$prefix}-item-row {
    padding-left: 20%;
    .#{$prefix}-item-control {
      .#{$prefix}-item-control-input {
        .#{$prefix}-item-control-input-content {
          > .ant-col {
            padding-left: 30%;
          }
        }
      }
    }
  }
}

.quotation-materials-title {
  .#{$prefix}-item-row {
    padding-left: 3%;
    .#{$prefix}-item-control {
      .#{$prefix}-item-control-input {
        .#{$prefix}-item-control-input-content {
          > .ant-col {
            padding-left: 30%;
          }
        }
      }
    }
  }
}

.shaping-form-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 360px;
  padding: 16px;
}

.quotation-services-title {
  .#{$prefix}-item-row {
    padding-left: 20%;
    .#{$prefix}-item-control {
      .#{$prefix}-item-control-input {
        .#{$prefix}-item-control-input-content {
          > .ant-row {
            padding-bottom: 36px;
            > .ant-row {
              padding-bottom: 24px;
              > .ant-col:nth-child(1) {
                span {
                  font-weight: 600;
                  padding: 16px 0 8px 0;
                  border-bottom: 2px solid $primaryColor;
                }
              }
              > .ant-col:nth-child(3) {
                .anticon {
                  color: $textColor;
                }
                .anticon:hover {
                  color: $primaryColor;
                }
              }
            }
          }
        }
      }
    }
  }
}

.quotation-accessories-title {
  .#{$prefix}-item-row {
    padding-left: 20%;
    .#{$prefix}-item-control {
      .#{$prefix}-item-control-input {
        .#{$prefix}-item-control-input-content {
          > .ant-row {
            padding-bottom: 36px;
            > .ant-row {
              padding-bottom: 24px;
              > .ant-col:nth-child(1) {
                span {
                  font-weight: 600;
                  padding: 16px 0 8px 0;
                  border-bottom: 2px solid $primaryColor;
                }
              }
              > .ant-col:nth-child(3) {
                .anticon {
                  color: $textColor;
                }
                .anticon:hover {
                  color: $primaryColor;
                }
              }
            }
          }
        }
      }
    }
  }
}

.quotation-summary-title {
  .#{$prefix}-item-row {
    padding-left: 10%;
  }
}

.ant-form .discount-label .ant-form-item-row .ant-form-item-label > label {
  color: red;
}

.discount-input.ant-input[disabled] {
  color: red;
}
